import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import MetaData from "../../components/meta"
import BackgroundImage from "gatsby-background-image"

// Styles
import "../../styles/traefik-ambassador-program.scss"

// Images
import ambassadorLogo from "../../images/ambassador/ambassador-logo.svg"
import featureDirectAccess from "../../images/ambassador/features/gopher-direct-access.svg"
import featureValuableRewards from "../../images/ambassador/features/gopher-rewards.svg"
import featureGetNoticed from "../../images/ambassador/features/gopher-get-noticed.svg"
import getInvolvedHashtag from "../../images/ambassador/what-we-ask/hashtag.svg"
import getInvolvedDiscord from "../../images/ambassador/what-we-ask/discord.svg"
import getInvolvedVideos from "../../images/ambassador/what-we-ask/videos.svg"
import getInvolvedDevelop from "../../images/ambassador/what-we-ask/develop.svg"

const AmbassadorPage = ({ data, location }) => {
  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="ambassador-page">
        {/* Hero */}
        <BackgroundImage
          fluid={data.hero_bg.childImageSharp.fluid}
          className="ambassador-hero page-hero"
          backgroundColor={`#06102a`}
          Tag="section"
        >
          <div className="wrapper-1200">
            <div className="left">
              <h1>Traefik Ambassador Application</h1>
              <p>
                Help others by sharing your expertise, contributing code,
                content, and building community.
              </p>
              <Link
                to="/traefik-ambassador-program/apply/"
                className="button--primary button--with-shadow button--extra-padding"
              >
                Apply Today
              </Link>
            </div>
            <div className="right">
              <img src={ambassadorLogo} alt="Traefik Ambassador Application" />
            </div>
          </div>
        </BackgroundImage>

        {/* Introduction */}
        <div className="ambassador-intro page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>What is a Traefik Ambassador?</h2>
              <p className="sub-title">
                Traefik Ambassadors are individuals who are dedicated to open
                source software, knowledgeable about using Traefik Labs
                products, and who wish to publicly champion its benefits to
                other developers.
              </p>
              <p className="sub-title">
                Check out the benefits and expectations and apply today!
              </p>
            </div>
          </div>
        </div>

        {/* Features */}
        <BackgroundImage
          fluid={data.features_bg.childImageSharp.fluid}
          className="ambassador-features page-content-section"
          backgroundColor={`#06102a`}
          Tag="section"
        >
          <div className="wrapper-1200">
            <div className="ambassador-feature ambassador-feature--access">
              <div className="ambassador-feature__content">
                <h3 className="heading--with-icon">Direct Access</h3>
                <ul className="list--with-check-icon">
                  <li>
                    <span>
                      Invitation to the private Quarterly Ambassador Roundtable
                      held with leadership and other Traefik Ambassadors
                      offering insights into the roadmap, configuration
                      improvements, and open discussion
                    </span>
                  </li>
                  <li>
                    <span>
                      Invitation to the private Discord Server with the global
                      Traefik Ambassador Community
                    </span>
                  </li>
                  <li>
                    <span>Direct access to the Traefik developer team</span>
                  </li>
                </ul>
              </div>
              <div className="ambassador-feature__image">
                <img src={featureDirectAccess} alt="Direct Access" />
              </div>
            </div>

            <div className="ambassador-feature ambassador-feature--rewards ambassador-feature--reverse">
              <div className="ambassador-feature__image">
                <img src={featureValuableRewards} alt="Valuable Rewards" />
              </div>
              <div className="ambassador-feature__content">
                <h3 className="heading--with-icon">Valuable Rewards</h3>
                <ul className="list--with-check-icon">
                  <li>
                    <span>Free Traefik Enterprise License</span>
                  </li>
                  <li>
                    <span>Traefik Ambassador Certificate</span>
                  </li>
                  <li>
                    <span>
                      Custom made Traefik Ambassador swag package, including a
                      shirt, hoodie, and stickers
                    </span>
                  </li>
                  <li>
                    <span>
                      Free enrollment to the Traefik Training Course by The Byte
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="ambassador-feature ambassador-feature--noticed">
              <div className="ambassador-feature__content">
                <h3 className="heading--with-icon">Get Noticed</h3>
                <ul className="list--with-check-icon">
                  <li>
                    <span>
                      Opportunity to start a Global Traefik Meetup group (paid
                      for and promoted by Traefik Labs) in your part of the
                      world
                    </span>
                  </li>
                  <li>
                    <span>
                      Opportunities to write Traefik Ambassador blog posts to be
                      hosted on traefik.io
                    </span>
                  </li>
                  <li>
                    <span>
                      Opportunities to present at Traefik Online Meetups,
                      promoted and featured by Traefik Labs
                    </span>
                  </li>
                </ul>
              </div>
              <div className="ambassador-feature__image">
                <img src={featureGetNoticed} alt="Get Noticed" />
              </div>
            </div>
          </div>
        </BackgroundImage>

        {/* Info */}
        <section className="ambassador-info page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>What We Ask</h2>
            </div>

            <ul className="list--with-check-icon">
              <li>
                <span>
                  To be present at the Quarterly Roundtable to the best of your
                  ability
                </span>
              </li>
              <li>
                <span>
                  Participate in Traefik Labs community initiatives such as
                  providing feedback to the User Survey, occasional polls or
                  surveys about product development, and being active at the
                  Roundtables
                </span>
              </li>
              <li>
                <span>Active engagement in the community</span>
              </li>
            </ul>

            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Get involved!</h2>
            </div>

            <ul className="ambassador-info__get-involved">
              <li>
                <img src={getInvolvedHashtag} alt="On social media" />
                <h3>On social media</h3>
                <p>
                  Share expertise about Traefik on social media, tagging Traefik
                </p>
              </li>
              <li>
                <img src={getInvolvedDiscord} alt="On Discord" />
                <h3>On Discord</h3>
                <p>Check in and communicate in the Discord server</p>
              </li>
              <li>
                <img src={getInvolvedVideos} alt="With posts or videos" />
                <h3>With posts or videos</h3>
                <p>
                  Write personal blogs or make video tutorials about Traefik
                </p>
              </li>
              <li>
                <img src={getInvolvedDevelop} alt="With code" />
                <h3>With code</h3>
                <p>Contribute code to any of the Traefik Labs product suite</p>
              </li>
            </ul>
          </div>
        </section>

        {/* CTA */}
        <section className="ambassador-cta page-footer">
          <div className="wrapper-1200">
            <h2>Come on Board!</h2>
            <p>Get started by applying</p>
            <div className="links">
              <Link
                to="/traefik-ambassador-program/apply/"
                className="button--primary button--primary--white button--extra-padding"
              >
                Apply Today
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    current_page: ghostPage(slug: { eq: "traefik-ambassador-program" }) {
      ...PageMetaFields
    }

    hero_bg: file(relativePath: { eq: "ambassador/hero-image@2x.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    features_bg: file(
      relativePath: { eq: "ambassador/features/features-bg@2x.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default AmbassadorPage
